import barba from "@barba/core";
import animations from "../animations/animations";

gsap.registerPlugin(ScrollToPlugin);

function playVideo(data) {
    const video = data.next.container.querySelector("video.hero__video");
    if (video) video.play();
}

const delay = (n) => {
    n = n || 2000;
    return new Promise((done) => {
        setTimeout(() => {
            done();
        }, n);
    });
};

const leaveAnimation = async () => {
    var tl = gsap.timeline();
    tl.to("#transition", {
        opacity: 1,
        visibility: "visible",
        translateY: "100vh",
        ease: "power1.inOut",
        duration: 1.5,
    });

    tl.to("#transition", {
        opacity: 0,
        visibility: "hidden",
    });

    tl.set("#transition", { translateY: "-170vh" });
    tl.set("#transition", { opacity: 1 });
};

const transition = () => {
    barba.init({
        sync: false,
        debug: true,
        transitions: [
            {
                once(data) {
                    document.body.classList.remove("invisible");
                    animations(data);
                },

                async beforeOnce(data) {
                    document.body.classList.add("invisible");
                    leaveAnimation();
                    await delay(500);
                    document.body.classList.remove("invisible");
                },

                async leave(data) {
                    leaveAnimation();
                    console.log("leave");
                    await delay(750);
                    gsap.to(window, { scrollTo: 0 }, "-=2");
                    playVideo(data);
                },

                enter(data) {
                    animations(data);
                },
            },
        ],
    });
};

export default transition;
